import React from "react"
import { RichText } from "prismic-reactjs"
import { graphql } from "gatsby"

import SEO from "../components/seo"

import CaseStudiesBody from '../components/caseStudies/caseStudiesBody'
  
export const query = graphql`
  query CaseStudies($uid: String) {
    prismic {
      allCase_studiess(uid: $uid) {
        edges {
          node {
            title
            lead_image
            overview_text
            deliverables_list
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

const CaseStudy = ({ data }) => {
  const doc = data.prismic.allCase_studiess.edges.slice(0,1).pop();
  if (!doc) return null;

  return (
    <React.Fragment>
      <SEO title={RichText.asText(doc.node.title)} />
      <CaseStudiesBody doc={doc.node} />
    </React.Fragment>
  )
}

export default CaseStudy
